<template>
    <div class="columns is-centered">
        <div class="column is-four-fifths is-full-touch">
            <p class="TextEulaEs">
                <strong>{{ i18n('End-User License Agreement') }}</strong><br>
                <br>
                Este Acuerdo de licencia de usuario final (EULA) es un acuerdo legal entre
                usted (ya sea como individuo o en nombre de una entidad) y Aumentar SAS con
                respecto a su uso de las aplicaciones de escritorio de LLS Incentives y la
                documentación asociada (el "Software"). SI NO ACEPTA TODOS LOS TÉRMINOS DE
                ESTE CLUF, NO INSTALE, USE NI COPIE EL SOFTWARE.<br>
                <strong>Resumen.</strong><br>
                Debe aceptar todos los términos de este CLUF para utilizar este Software.<br>
                Si es así, puede utilizar el Software de forma gratuita y para cualquier
                fin lícito.<br>
                Este Software se comunica automáticamente con su servidor por tres razones:
                (1) para recibir e instalar actualizaciones; (2) para enviar informes de errores;
                y (3) para enviar información de uso anónima. Puede ver datos de muestra para
                ver qué información se envía y puede optar por no enviar los datos de uso anónimos.
                Este Software se proporciona "tal cual" sin garantías, y usted acepta que Aumentar
                SAS no es responsable de nada de lo que haga con él.<br>
                Realmente debería seguir adelante y leer todo el EULA. No es tan largo.
                No solo debe confiar en este resumen.<br>
                <strong>El Acuerdo.</strong><br>
                Al descargar, instalar, usar o copiar el Software, usted acepta y acepta estar
                sujeto a los términos de este EULA. Si no está de acuerdo con todos los términos
                de este EULA, no puede descargar, instalar, usar o copiar el Software.<br>
                <strong>La Licencia.</strong><br>
                Este EULA le da derecho a instalar tantas copias del Software como desee y a
                utilizar el Software para cualquier fin lícito de conformidad con este EULA.
                Su licencia para usar el Software está expresamente condicionada a su aceptación
                de todos los términos de este EULA. Este software tiene licencia, no se vende.
                Aumentar SAS se reserva todos los demás derechos no otorgados por este EULA.<br>
                <strong>Las Restricciones.</strong><br>
                Al usar el Software, debe usarlo de manera que cumpla con las leyes aplicables
                en las jurisdicciones en las que usa el Software.
                No puede vender, revender, alquilar, arrendar ni intercambiar el Software por
                nada de valor.
                Puede redistribuir el software, pero debe incluir este EULA y no puede volver
                a empaquetar ni empaquetar el Software con ningún otro software.
                No puede eliminar ni alterar ningún aviso o marca de propiedad en el Software.<br>
                <strong>Avisos de Privacidad.</strong><br>
                El Software se comunica automáticamente con su servidor con tres propósitos:
                (1) actualizar el Software; (2) enviar informes de errores; y (3) enviar datos
                de uso anónimos para que podamos mejorar el Software. Si desea obtener más
                información sobre la información específica que enviamos, visite
                https://lls.aumentar.com.co/docs/privacy .
                Puede optar por no enviar los datos de uso anónimos, pero si no desea que el
                Software se actualice automáticamente o envíe informes de error, debe
                desinstalarlo.<br>
                <strong>Actualizaciones Automáticas de Software.</strong><br>
                El Software se comunica con su servidor (y envía la información descrita en la URL
                anterior) para determinar si hay parches, correcciones de errores, actualizaciones,
                mejoras u otras modificaciones para mejorar el Software. Usted acepta que el
                Software puede instalar automáticamente dichas mejoras en el Software en su
                computadora sin proporcionar ningún aviso adicional ni recibir ningún consentimiento
                adicional. Esta función no se puede desactivar. Si no desea recibir actualizaciones
                automáticas, debe desinstalar el Software.<br>
                <strong>Informes de Errores.</strong><br>
                Para ayudarnos a mejorar el Software, cuando el Software encuentre ciertos errores,
                enviará automáticamente cierta información a su servidor sobre el error (como se
                describe en la URL anterior). Esta función no se puede desactivar. Si no desea
                enviar informes de errores a su servidor, debe desinstalar el Software.<br>
                <strong>Datos de Uso Anónimo.</strong><br>
                LLS Incentives recopila datos anónimos sobre su uso del Software
                para ayudarnos a hacerlo más increíble. Aproximadamente una vez al día, el Software
                envía dichos datos (como se describe con más detalle en la URL anterior) a su
                servidor. Si no desea enviar datos de uso anónimos al servidor, puede optar por no
                hacerlo cambiando su configuración en la vista de Preferencias.<br>
                <strong>Avisos de Código Abierto.</strong><br>
                Ciertos componentes del Software pueden estar sujetos a licencias de software de
                código abierto ("Componentes de código abierto"), lo que significa cualquier
                licencia de software aprobada como licencia de código abierto por la Iniciativa de
                código abierto o cualquier licencia sustancialmente similar, incluida, entre otras,
                cualquier licencia que, como condición de distribución del software con licencia
                bajo dicha licencia, requiera que el distribuidor haga que el software esté
                disponible en formato de código fuente. La documentación del Software incluye copias
                de las licencias aplicables a los Componentes de código abierto. <br>
                En la medida en que exista un conflicto entre los términos de la licencia que cubren
                los Componentes de código abierto y este EULA, se aplicarán los términos de dichas
                licencias en lugar de los términos de este EULA. En la medida en que los términos de
                las licencias aplicables a los Componentes de código abierto prohíban cualquiera de
                las restricciones de este Acuerdo con respecto a dicho Componente de código abierto,
                dichas restricciones no se aplicarán a dicho Componente de código abierto. En la
                medida en que los términos de las licencias aplicables a los Componentes de código
                abierto requieran que el Licenciante haga una oferta para proporcionar el código
                fuente en relación con el Producto, dicha oferta se realiza por la presente y
                usted puede ejercerla comunicándose con gerencia@aumentar.com.co . <br>
                <strong>Avisos de Propiedad Intelectual.</strong><br>
                El Software y todos los derechos de autor, secretos comerciales y otros derechos
                de propiedad intelectual en todo el mundo son propiedad exclusiva de Aumentar SAS.
                Aumentar SAS se reserva todos los derechos sobre el Software que no se le otorgan
                expresamente en este EULA.<br>
                <strong>Exenciones de Responsabilidad y Limitaciones de Responsabilidad.</strong>
                <br>
                EL SOFTWARE SE PROPORCIONA "TAL CUAL" Y NO SE OTORGA NINGUNA GARANTÍA, NI EXPLÍCITA
                NI IMPLÍCITA. SU USO DEL SOFTWARE ES BAJO SU PROPIO RIESGO. Aumentar SAS no
                garantiza que: (i) el Software cumpla con sus requisitos específicos; (ii) el
                Software es totalmente compatible con cualquier plataforma en particular; (iii)
                su uso del Software será ininterrumpido, oportuno, seguro o libre de errores; (iv)
                los resultados que puedan obtenerse del uso del Software serán exactos o fiables;
                (v) la calidad de cualquier producto, servicio, información u otro material comprado
                u obtenido por usted a través del Software cumplirá con sus expectativas; o (vi) se
                corregirá cualquier error en el Software.<br>
                USTED ENTIENDE Y ACEPTA EXPRESAMENTE QUE Aumentar SAS NO SERÁ RESPONSABLE DE NINGÚN
                DAÑO DIRECTO, INDIRECTO, INCIDENTAL, ESPECIAL, CONSECUENTE O EJEMPLAR, INCLUYENDO,
                ENTRE OTROS, DAÑOS POR PÉRDIDA DE BENEFICIOS, FONDO DE COMERCIO, USO, DATOS U OTRAS
                PÉRDIDAS INTANGIBLES (INCLUSO SI Aumentar SAS HA SIDO ADVERTIDO DE LA POSIBILIDAD
                DE TALES DAÑOS) RELACIONADOS CON EL SOFTWARE, incluyendo, por ejemplo: (i) el uso
                o la imposibilidad de usar el Software; (ii) el costo de adquisición de bienes y
                servicios sustitutos que resulten de bienes, datos, información o servicios
                comprados u obtenidos o mensajes recibidos o transacciones realizadas a través o
                desde el Software; (iii) acceso no autorizado o alteración de sus transmisiones o
                datos; (iv) declaraciones o conducta de cualquier tercero en el Software; (v) o
                cualquier otro asunto relacionado con el Software.<br>
                Aumentar SAS se reserva el derecho en cualquier momento y de vez en cuando de
                modificar o descontinuar, temporal o permanentemente, el Software (o cualquier
                parte del mismo) con o sin previo aviso. Aumentar SAS no será responsable ante
                usted o cualquier tercero por cualquier modificación, cambio de precio, suspensión
                o discontinuidad del Software.<br>
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Privacy',
    inject: ['i18n', 'http', 'route'],

    computed: {
    },
};
</script>

<style lang="scss">

.TextEulaEs{
    text-align: justify;
}

</style>
